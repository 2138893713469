// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Swisscom: Facilities',
  filename: 'environment.facilities-83677778.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {
    emailLink: false,
    textMessage: false,
    usernamePassword: true,
    google: false
  },

  firebaseConfig: {
    apiKey: "AIzaSyBANGGrozbeowrngdUTTXcRJktFNj8RC54",
    authDomain: "facilities-83677778.firebaseapp.com",
    databaseURL: "https://facilities-83677778-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-83677778",
    storageBucket: "facilities-83677778.appspot.com",
    messagingSenderId: "591707614036",
    appId: "1:591707614036:web:5ed2808b75d346de3036c1"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-83677778.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,
  },





};
